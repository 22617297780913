.DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
  background: #f7ad17 !important;
  color: white !important;
}

.Selectable .DayPicker-Day {
  border-radius: 0;
  overflow: visible !important;
  z-index: 10;
  font-size: 0.875rem;
}

.DayPicker-Day:not(.DayPicker-Day--selected):hover {
  background: white !important;
}

.Selectable .DayPicker-Day--start {
  border-top-left-radius: 4px !important;
  border-bottom-left-radius: 4px !important;
  background: #f7ad17 !important;
}

.Selectable .DayPicker-Day--end {
  border-top-right-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
  background: #f7ad17 !important;
}

.MuiPopover-paper {
  max-width: 100% !important;
}

.DayPicker {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  padding-top: 1rem;
}

.DayPicker .DayPicker-Caption div {
  margin: 0 auto;
}

.DayPicker .DayPicker-Months {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  margin: 0;
  padding: 0;
}

.DayPicker .DayPicker-Months .DayPicker-Week {
  line-height: 1.875rem !important;
  border-bottom: 0.25rem solid white;
}

.DayPicker .DayPicker-Months .DayPicker-Week .DayPicker-Day--outside {
  background: white !important;
}

.DayPicker .DayPicker-Months .DayPicker-Week .DayPicker-Day {
  margin-top: 0.625rem !important;
  height: 2.5rem !important;
  min-width: 2.5rem !important;
  padding: 0;
}

.DayPicker .DayPicker-Month {
  margin-top: 0;
}

.DayPicker .DayPicker-Body .DayPicker-Day--disabled.DayPicker-Day--today {
  color: #dce0e0 !important;
}

.DayPicker .DayPicker-Day--today {
  font-weight: 400 !important;
  color: black;
}

.DayPicker .DayPicker-Month .DayPicker-Caption {
  width: auto !important;
}
